<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="this.pageTitle"
      :subtitle="'Formulario para crear y editar agencias'"
      :hide-buttons="true"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="name" class="w-full select-large">Nombre</label>
          <vs-input
            id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("name") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label for="institution" class="w-full select-large">Institución</label>
          <v-select
            id="institution"
            name="institution"
            label="name"
            index="id"
            :options="institutions"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.institution"
            v-validate="'required'"
            class="w-full select-large"
          />
          <span class="text-danger">{{ errors.first("institution") }}</span>
          <br/>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label for="category" class="w-full select-large">Categoría</label>
          <v-select
            id="category"
            name="category"
            label="name"
            index="id"
            :options="categories"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.category"
            v-validate="'required'"
            class="w-full select-large"
          />
          <span class="text-danger">{{ errors.first("category") }}</span>
          <br/>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label for="state" class="w-full select-large">Departamento</label>
          <v-select
            id="state"
            name="state"
            label="name"
            index="id"
            :options="states"
            :placeholder="'Seleccione..'"
            :searchable="true"
            v-model="entryModel.state"
            v-validate="'required'"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("state") }}</span>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="description" class="w-full select-large">Descripción de agencia</label>
          <editor id="description" :content.sync="entryModel.description.html" :resourceType="resource"/>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="address" class="w-full select-large">Dirección</label>
          <vs-textarea
            id="address" name="address" v-model="entryModel.address" v-validate="'required'" rows="3"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("address") }}</span>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="latitude" class="w-full select-large">Latitud</label>
          <vs-input
            id="latitude" name="latitude" v-model="entryModel.geolocation.lat" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("latitude") }}</span>
          <br/>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label for="longitude" class="w-full select-large">Longitud</label>
          <vs-input
            id="longitude" name="longitude" v-model="entryModel.geolocation.lng" v-validate="'required'"
            class="w-full" autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("longitude") }}</span>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="featured" class="w-full select-large">Destacado</label>
          <vs-select
            id="featured" name="featured" v-model="entryModel.featured" v-validate="'required'"
            class="w-full select-large"
          >
            <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
            <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
          </vs-select>
          <span class="text-danger">{{ errors.first("featured") }}</span>
          <br/>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label for="priority" class="w-full select-large">Prioridad</label>
          <vs-input
            type="number"
            id="priority"
            name="priority"
            min="1"
            max="10"
            v-model="entryModel.priority"
            v-validate="'required'"
            class="w-full"
          />
          <span class="text-danger">{{ errors.first("priority") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToEntriesList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry" :disabled="!validateForm">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import Editor from "./../../components/Editor.vue";
  import {Validator} from "vee-validate";

  let notifier = new Notifier();
  const ENTRY_CLIENT = new EntriesClient("stores");
  const INSTITUTIONS_CLIENT = new CoreClient("institutions");
  const ATTRIBUTE_OPTIONS_ENTRY_CLIENT = new AttributeOptionsClient("stores");
  const STATES_CLIENT = new CoreClient("states");

  const requiredFields = [
    "name", "category", "institution", "address", "latitude", "longitude", "featured", "priority"
  ];
  let dict = requiredFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        pageTitle: "Agencias y Sucursales",
        resource: "stores",
        institutions: [],
        categories: [],
        states: [],
        entryModel: {
          institution: null,
          name: null,
          description: {
            html: null
          },
          category: null,
          address: null,
          state: null,
          geolocation: {
            lat: null,
            lng: null,
          },
          priority: 10,
          featured: false,
        }
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id)
          .then(() => {
            this.$vs.loading.close();
          });
      } else {
        this.$vs.loading();
        this.getInstitutions(false)
          .then(() => {
            this.getCategories(false)
              .then(() => {
                this.getStates(false)
                  .then(() => {
                    this.$vs.loading.close();
                  });
              });
          });
      }
    },

    computed: {
      validateForm() {
        return !this.errors.any()
          && requiredFields.reduce((accumulator, currentValue) => {
            return accumulator && this.entryModel[currentValue] !== null;
          })
          && this.entryModel.description.html !== null
          && this.entryModel.description.html !== "";
      }
    },

    methods: {
      async getInstitutions(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await INSTITUTIONS_CLIENT.all({queryStrings: {representation_type: "simple"}})
          .then(response => {
            this.institutions = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getStates(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await STATES_CLIENT.all()
          .then(response => {
            this.states = response.data;
          }).catch(error => {
            notifier.notification("error");
          });
        this.$vs.loading.close();
      },

      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await ATTRIBUTE_OPTIONS_ENTRY_CLIENT.categories({queryStrings: {representation_type: "simple"}})
          .then(response => {
            this.categories = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            this.getInstitutions(false)
              .then(() => {
                this.getCategories(false)
                  .then(() => {
                    this.getStates(false).then(() => {
                      Object.assign(this.entryModel, response.data);
                    });
                  });
              });
          }).catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.cleanModel();

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          this.$vs.loading();
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToEntriesList();
            })
            .catch(error => {
              console.log(error);
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });

        } else {
          this.$vs.loading();
          await ENTRY_CLIENT.create({data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToEntriesList();
            })
            .catch(error => {
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },

      goToEntriesList() {
        this.$router.push({name: "stores"});
      }
    },
    components: {
      Editor,
      FormWizard,
      TabContent,
      flatPickr,
      "v-select": vSelect
    }
  };
</script>